var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.partner.id)?_c('div',{class:[
        'participant-card',
        { 'participant-card--deleted': _vm.isEmployeeDeleted }
    ]},[_c('div',{staticClass:"participant-card__partner-detail"},[_c('ProfileAvatar',{class:[
                'participant-card__avatar',
                { 'participant-card__avatar--deleted': _vm.isEmployeeDeleted }
            ],attrs:{"image":_vm.partner.avatarUrl}}),_c('div',{staticClass:"participant-card__partner-detail-text"},[(_vm.partnerDisplayName)?_c(_vm.nameElement,{ref:"participantName",tag:"component",class:_vm.nameClass,attrs:{"to":_vm.profilePath}},[_vm._v(" "+_vm._s(_vm.partnerDisplayName)+" "),(_vm.isEmployeeDeleted)?_c('span',{staticClass:"participant-card__partner-detail-text--deactivated"},[_vm._v(" (Deactivated) ")]):_vm._e()]):_vm._e(),_c('p',{staticClass:"participant-card__partner-title"},[_vm._v(_vm._s(_vm.partnerTitle))]),_c('p',{staticClass:"participant-card__partner-group"},[_vm._v(_vm._s(_vm.partnerGroup))]),(_vm.isDEAdmin)?_c('div',[(_vm.participantType === _vm.EMPLOYEE_ROLE)?_c('p',{staticClass:"participant-card__partner-email"},[_vm._v(" "+_vm._s(_vm.$store.state.page.employee.data.email)+" ")]):_vm._e(),(_vm.participantType === _vm.EDUCATOR_ROLE)?_c('p',[_vm._v(" "+_vm._s(this.request.educatorId)+" ")]):_vm._e()]):_vm._e()],1)],1),_vm._t("default",function(){return [(_vm.showMessage)?_c('NebulaButton',{staticClass:"participant-card__message-button",attrs:{"text":"Message","type":"ghost","size":"l","iconLeft":"mail","routerLink":"","link":_vm.messageLink}}):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }